import { render, staticRenderFns } from "./MiniFooter.vue?vue&type=template&id=c5634e14&"
import script from "./MiniFooter.vue?vue&type=script&lang=js&"
export * from "./MiniFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/builds/reifen_gundlach/frontend/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
