//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'MiniFooter',
  props: {
    contentLeft: {
      type: Array,
      default: () => {}
    },
    contentRight: {
      type: Array,
      default: () => {}
    },
    footnote: {
      type: String,
      default: ''
    }
  }
})
